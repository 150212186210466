html, body {
  height: 100%;
  background-color: #E6E7E9;
}

img {
  max-width: 100%;
}

.page {
  > .container {
    padding: 0 36px;
    background-color: white;
    > .suck-in {
      margin: 0 -36px;
    }
  }

  p {
    font-size: 1.3em;
    line-height: 1.4em;
    font-weight: 200;
    margin-bottom: 1.3em;
  }

  .panel .panel-body {
    h3 {
      font-size: 22px;
    }
    p {
      font-size: 1em;
    }
  }
}

.site-banner {
  background-color: #38454D;
  color: white;
  text-align: center;
  margin-bottom: 40px !important;

  p.lead {
    font-weight: 200;
    font-family: $headings-font-family;
  }

  a {
    color: white;
  }
}

.site-header {
  .navbar.navbar-default {
    margin-bottom: 10px;
    background-color: #E6E7E9;
    border: none;

    > .container {
      background-color: white;
    }

    a {
      color: #222;
    }
  }

  .nav.navbar-right {
    text-transform: uppercase;

    li a {
      font-weight: 400;
    }

    li a svg {
      display: inline-block;
      height: 15px;
      margin-right: 8px;
      padding-top: 2px;
      fill: #38454D;
    }

    li:hover, li.active {
      a {
        background: #00AEEF;
        color: white;
      }
      svg {
        fill: white;
      }
    }
  }
}

footer {
  text-align: center;
  margin-top: 10px;

  > .container {
    background-color: #38454D;
  }

  img {
    margin-top: 8px;
  }

  .love-equals-power {
    color: #DFDFDF;
    font-family: Helvetica Neue, sans-serif;
    font-size: 1.2em;
    margin: 12px 0;

    white-space: pre-line;
    word-wrap: break-word;

    .equals {
      font-weight: 100;
      margin: 0 2px;
    }
    .love, .power, .beauty, .truth, .peace {
      font-weight: 400;
    }
  }
}

.post-body {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.separator {
  margin: 0 10px;
  color: $gray-lighter;
}

.page p.post-meta {
  font-size: 1em;
}

.hr-lg {
  margin-top: 36px;
  margin-bottom: 36px;
}

.eileen-sq {
  float: left;
  margin: 0 24px 24px 0;
  border-radius: 2px;
  width: 128px;
}

.newsletter-signup {
  p {
    font-size: 1em;
  }
}

.post-image {
  margin-bottom: 20px;
}

.event-date {
  margin-left: 8px;

  svg {
    display: inline-block;
    height: 15px;
    margin-right: 4px;
    padding-top: 2px;
    fill: $text-muted;
  }
}

.list-unstyled .event-date {
  margin-left: 2px;
  display: block;
}

.event-link {
  display: block;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { }

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  .site-banner .banner-title {
    text-align: left;
    h1 {
      margin-top: 4em;
    }
  }

  .post-image {
    float: left;
    width: 200px;
    margin: 0 20px 20px 0;
  }
  .exerpt .post-image {
    width: 150px;
    margin: 4px 20px 40px 0;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { }
